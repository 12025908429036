import React, { memo } from 'react';
import useDimensions from "react-use-dimensions";
import ContactForm from './contact-form'

function Contact() {
    const [ref, { height }] = useDimensions();

    // console.log(`this node is ${height}px tall`);

    // console.log('rendering contact');
    return (
        <section id={`contact`} ref={ref} className="pricing section contact" style={{ height }}>
            <div className="contact-left-decoration"/>
            <div className="container">
                <div className="pricing-inner section-inner has-top-divider contact-inner">
                    <h2 className="section-title mt-0 text-center hero-title is-revealing">Get in touch today!</h2>
                    <ContactForm/>
                </div>
            </div>
        </section>);
}

export default memo(Contact);