import React, { PureComponent } from 'react';
import ProfilePhoto from './profile-photo';

class Hero extends PureComponent {
    render() {
        return (
            <section className="hero">
                <div className="hero-left-decoration is-revealing"/>
                <div className="hero-right-decoration is-revealing"/>
                <div className="container">
                    <div className="hero-inner">
                        <ProfilePhoto/>
                        <div className="hero-copy">
                            <div className={'hero-title is-revealing'}>
                                <h1>Katie Whitman</h1>
                                <p>Independent College Counselor</p>
                            </div>
                            <p className="hero-paragraph is-revealing">Feeling confused or overwhelmed about college applications? I can give you personalized guidance on your college admissions journey. </p>
                            <p className="hero-paragraph is-revealing">I work directly with students and their families to help them develop an admissions profile based on individual strengths and interests, and effectively highlight academic and extracurricular achievements in their college applications.</p>
                            <p className="hero-cta mb-0 is-revealing">
                                <a className="button button-primary button-shadow" href={`#contact`}>Get in
                                    touch</a>
                            </p>
                        </div>
                        <div className="hero-illustration"/>
                    </div>
                </div>
            </section>
        );
    }
}

Hero.propTypes = {};

export default Hero;