import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

class Faqs extends PureComponent {
    render() {
        return (
            <section id={`faqs`} className="pricing section">
                <div className="pricing-right-decoration"/>
                <div className="container">
                    <div className="pricing-inner section-inner has-top-divider">
                        <h2 className="section-title mt-0 text-center hero-title is-revealing">Answering questions?</h2>
                        <div className="pricing-faqs container-sm is-revealing">
                            <ul className="accordion">
                                <li>
                                    <div className="accordion-title">
                                        <span>Fancy animations are cool, igitur sumus</span>
                                        <div className="accordion-icon"></div>
                                    </div>
                                    <div className="accordion-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt
                                            ut
                                            labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="accordion-title">
                                        <span>In a professional context it often happens that private?</span>
                                        <div className="accordion-icon"></div>
                                    </div>
                                    <div className="accordion-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt
                                            ut
                                            labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="accordion-title">
                                        <span>Lorem ipsum dolor sit amet, consectetur</span>
                                        <div className="accordion-icon"></div>
                                    </div>
                                    <div className="accordion-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt
                                            ut
                                            labore et dolore magna aliqua. Ut enim ad minim veniam. Ut enim ad
                                            minim veniam semper.
                                            Lorem
                                            ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                            tempor incididunt ut
                                            labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="accordion-title">
                                        <span>In a professional context it often happens that private?</span>
                                        <div className="accordion-icon"></div>
                                    </div>
                                    <div className="accordion-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt
                                            ut
                                            labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="accordion-title">
                                        <span>Lorem ipsum dolor sit amet, consectetur</span>
                                        <div className="accordion-icon"></div>
                                    </div>
                                    <div className="accordion-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt
                                            ut
                                            labore et dolore magna aliqua. Ut enim ad minim veniam. Ut enim ad
                                            minim veniam semper.
                                            Lorem
                                            ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                            tempor incididunt ut
                                            labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Faqs.propTypes = {};

export default Faqs;