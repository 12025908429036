import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade'
import placeholder from '../../images/form-placeholder.svg';

const styles = theme => ({
    div: {
        position: `absolute`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        width: `100%`,
    },
    img: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,

        [`@media (min-width: ${theme.customBreakpoints.md})`]: {
            width: `50%`,
        },
    },
    p: {
        maxWidth: 480,
        textAlign: `center`
    }
});

class FormPlaceholder extends PureComponent {
    render() {
        // console.log('render FormPlaceHolder');
        const { classes, show } = this.props;
        return (
            <Fade in={show}>
                <div className={classes.div}>
                    <img className={classes.img} src={placeholder} alt={'form placeholder'}/>
                    <p className={classes.p}>Thank you for reaching out. I usually respond to messages via email within
                        48 hours.</p>
                </div>
            </Fade>
        );
    }
}

FormPlaceholder.propTypes = {
    classes: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired
};

export default withStyles(styles)(FormPlaceholder);