import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const styles = theme => ({
    div: {
        width: `100%`,
        display: `flex`,
        justifyContent: `center`,
        [`@media (min-width: ${theme.customBreakpoints.sm}px)`]: {
            justifyContent: `flex-end`
        },
    },
    button: {
        width: `280px`,
        margin: `${theme.spacing.unit * 4}px 0`,
        background: theme.palette.primary.main,
        borderRadius: 2,
        border: 0,
        color: `white`,
        height: 48,
        padding: `0 30px`,
        fontFamily: `"Arimo", sans-serif`,
        fontSize: `16px`,
        letterSpacing: `-0.1px`,
        fontWeight: 700,
        lineHeight: `16px`,

        '&:disabled > span': {
            opacity: .2
        },
        [`@media (min-width: ${theme.customBreakpoints.sm}px)`]: {
            width: `auto`,
        },
    },
    buttonLabel: {
        transition: theme.transition,
        textTransform: `none`,
        color: `white`,
    }
});

function SubmitButton(props) {


    const { classes, disabled } = props;
    // console.log('rendering button disabled', disabled);
    return (
        <div className={classes.div}>
            <p className="hero-cta mb-0 is-revealing">
                <Button
                    size={'large'}
                    disabled={disabled}
                    classes={{ root: classes.button, label: classes.buttonLabel }}
                    type="submit">
                    Send message
                </Button>
            </p>
        </div>

    );
}

export default memo(withStyles(styles)(SubmitButton), ({disabled: current}, {disabled: next}) => current === next);