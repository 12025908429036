import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: `100%`,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 4,
        outline: 'none',
        textAlign: `center`
    },
    h1: {
        marginTop: theme.spacing.unit,
    }
});

class SimpleModal extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.open !== this.props.open
    }

    render() {
        const { classes, open, onClick } = this.props;
        const showModal = open ? open : false;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={showModal}
                onClick={onClick}
                onClose={onClick}
            >
                <div className={classes.paper}>
                    <h1 className={classes.h1}>
                        {`Couldn't send your message`}
                    </h1>
                    <p>
                        Check your internet collection, reload the page, and try again.
                    </p>
                </div>
            </Modal>
        );
    }
}

SimpleModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;