import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { TextValidator } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';


const NUM_MULTILINE_ROWS = 12;

const styles = theme => ({
    helperText: {
        fontFamily: `"Oxygen", sans-serif`,
        position: `absolute`,
        top: 50,
    },
    multilineHelperText: {
        position: `absolute`,
        top: 310,
        fontFamily: `"Oxygen", sans-serif`
        // color: theme.palette.primary.main,
    },
    single: {
        width: `100%`,
        marginBottom: 48,
        [`@media (min-width: ${theme.customBreakpoints.sm}px)`]: {
            marginBottom: 56,
            width: `42%`,
            '&:not(:last-child)': {
                marginRight: 48,
            },
        },
    },
    multiline: {
        marginTop: `${theme.spacing.unit}px`,
        width: `100%`,
    },
});

const fontStyle = {
    fontFamily: `"Oxygen", sans-serif`
};

function InputField(props) {
    // console.log('rendering input-field props', props);
    const {
        multiline,
        name,
        label,
        placeholder,
        disabled,
        onChange,
        value, classes,
        validators,
        errorMessages,
        helperText,
        validatorListener,
    } = props;

    const variant = multiline ? `outlined` : `standard`;

    return (
        <TextValidator
            multiline={multiline}
            rows={NUM_MULTILINE_ROWS}
            name={name}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            value={value}
            validators={validators}
            errorMessages={errorMessages}
            helperText={helperText}
            InputLabelProps={{ style: fontStyle }}
            InputProps={{ style: fontStyle }}
            className={ multiline ? classes.multiline : classes.single}
            FormHelperTextProps={{
                classes: {
                    root: multiline
                        ? classes.multilineHelperText
                        : classes.helperText
                }
            }}
            variant={variant}
            validatorListener={validatorListener}
        />
    );
}

InputField.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
};
export default withStyles(styles)(memo(InputField));

