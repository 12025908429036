import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import img1 from '../images/about-illustration-1.svg'
import img2 from '../images/about-illustration-2.svg'

class Faqs extends PureComponent {
    render() {
        return (
            <section id={`about`} className="pricing section about has-top-divider">
                <div className="container">
                    <div className="about-inner">
                        <div className="about-illustrations">
                            <img alt="self-portrait" className={'about-illustration about-illustration-one  is-revealing'} src={img1}/>
                            <img alt="self-portrait" className={'about-illustration about-illustration-two  is-revealing'} src={img2}/>
                        </div>
                        <div className="about-copy">
                            <h1 className="hero-title about-title mt-0 is-revealing">About me</h1>
                            <p className="about-paragraph is-revealing">I began guiding high school students over ten years ago while attending Columbia University, as a resident advisor for Columbia’s summer high school program. After graduating from Columbia with a Bachelor’s degree in mathematics and economics, I joined Credit Suisse’s Investment Banking division in New York, where I honed my analytical skills and attention to detail, but missed working with students.</p>
                            <p className="about-paragraph is-revealing">In 2012, I moved to Beijing to study Chinese language at Tsinghua University’s Inter-University Program (IUP) and achieve working fluency in Mandarin. Upon completing IUP, I transitioned into a full-time career in education.</p>
                            <p className="about-paragraph is-revealing">Since then, I’ve worked with over 400 American and Chinese students as Resident Director for CET Academic Programs’ Intensive Chinese Language program, Associate Director of Student Life for Schwarzman Scholars at Tsinghua University, Director of Residential Life for the Middlebury Interactive Languages Beijing Academy, and an admissions consultant to Chinese students planning to attend college in the US.</p>
                            <p className="about-paragraph is-revealing">In August 2018, I moved back to the US with my husband and our orange tabby cat, Huang Di. From my home base in Bellevue, WA, I specialize in advising high school students and their families from around the US and abroad in <a href={'#services'}>the areas above</a> in both English and Mandarin.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Faqs.propTypes = {};

export default Faqs;
