import React, { useState, useRef, useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import FormGroup from '@material-ui/core/FormGroup';
import { ValidatorForm } from 'react-material-ui-form-validator';

import Dialog from './dialog';

import InputField from './input-field';
import SubmitButton from './submit-button';
import FormPlaceholder from './form-placeholder';

const formStatus = {
    success: 'SUCCESS',
    fail: 'FAIL',
    pending: 'PENDING',
};

const MIN_MESSAGE_LENGTH = 140;

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
};

const initialState = {
    name: '',
    email: '',
    message: ''
};

const styles = theme => ({
    form: {
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        alignItems: `center`,
        position: `relative`,
    },
    formGroup: {
        width: `100%`,
        zIndex: 2,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,

        [`@media (min-width: ${theme.customBreakpoints.sm}px)`]: {
            flexDirection: `row`,
            justifyContent: `flex-start`,
        },
    },
});

function ContactForm(props) {
    const [disabled, setDisabled] = useState(true);
    const [formData, setFormData] = useState(initialState);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {
        if (submitStatus === formStatus.pending) {
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({ 'form-name': 'contact', ...formData })
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSubmitStatus(formStatus.success);
                    } else {
                        setSubmitStatus(formStatus.fail);
                        setSubmitted(false);
                    }
                })
                .catch(error => {
                    setSubmitStatus(formStatus.fail);
                    setSubmitted(false);
                    console.log('error', error);
                });
        }

    }, [submitStatus]);


    const formElement = useRef(null);

    const handleModalClick = () => {
        // console.log('handleModalClick');
        setSubmitStatus(null);
    };

    const validatorListener = (result) => {
        // console.log('result in validatorListener', result);
        setDisabled(!result);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        // console.log('in handle change',{ ...formData, [name]: value } )
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setSubmitted(true);
        setSubmitStatus(formStatus.pending);
    };

    // console.log('rendering contact form');

    const { classes } = props;
    const { name, email, message } = formData;
    const isLongEnough = message.length >= MIN_MESSAGE_LENGTH;
    const remainingCharCount = MIN_MESSAGE_LENGTH - message.length;
    const helperText = isLongEnough
        ? ''
        : `*must be at least ${remainingCharCount} character${remainingCharCount === 1 ? '' : 's'} longer`;
    return (
        <Fragment>
            <Dialog onClick={handleModalClick} open={submitStatus === formStatus.fail}/>
            <FormPlaceholder show={submitStatus === formStatus.success}/>
            <div className="pricing-faqs container-sm is-revealing">
                <Fade unmountOnExit={true} in={submitStatus !== formStatus.success}>
                    <ValidatorForm
                        action={`#`}
                        method='post'
                        name={`contact`}
                        data-netlify={`true`}
                        data-netlify-honeypot={`bot-field`}
                        className={classes.form}
                        ref={formElement}
                        onSubmit={handleSubmit}
                    >
                        <input type="hidden" name="form-name" value="contact"/>
                        <FormGroup classes={{ root: classes.formGroup }}>
                            <InputField
                                multiline={false}
                                disabled={submitted}
                                name={`name`}
                                label={`Name`}
                                onChange={handleChange}
                                value={name}
                                validators={['required', 'matchRegexp:^[A-Za-z\\s]+$']}
                                errorMessages={['this field is required', 'only letters allowed here']}
                                validatorListener={validatorListener}
                            />
                            <InputField
                                multiline={false}
                                disabled={submitted}
                                name={`email`}
                                label={`Email`}
                                onChange={handleChange}
                                value={email}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                                validatorListener={validatorListener}
                            />
                        </FormGroup>
                        <InputField
                            multiline={true}
                            disabled={submitted}
                            name={`message`}
                            label={`Message`}
                            placeholder={`Please tell me a bit about what you'd like my help with…`}
                            onChange={handleChange}
                            value={message}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            helperText={helperText}
                        />
                        <SubmitButton disabled={!isLongEnough || disabled || submitted}/>
                    </ValidatorForm>
                </Fade>
            </div>
        </Fragment>
    );
}

export default withStyles(styles)(ContactForm);