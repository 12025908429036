//#3525D3
import { createMuiTheme } from '@material-ui/core/styles';

export const palette = {
    primary: {
        main: '#3525D3',
    },
};


export default createMuiTheme({
    transition: `all 200ms ease-in`,
    customBreakpoints: {
        xs: 0,
        sm: 481,
        md: 641,
        lg: 1025,
        xl: 1921,
    },
    spacing: {
        unit: 16,
    },
    palette,
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Arimo',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});