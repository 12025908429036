import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import icon1 from '../images/tabs-icon-01.svg'
import icon2 from '../images/tabs-icon-02.svg'
import icon3 from '../images/tabs-icon-03.svg'


const tabsData = [
    {
        title: `Free Consultation`,
        icon: icon2,
        id: 'tab-1',
        body: ["Just like picking colleges, finding the right college counselor is all about fit. I want you to be able to get to know me, and for me to get to know you, before making a decision about working together long term, so I offer a free first meeting to all students and their parents.",
            <span>Contact me anytime using <a
                href={"#contact"}>the form below</a> to set up a free consultation.</span>]
    },
    {
        title: `Finding the perfect fit`,
        icon: icon3,
        id: 'tab-2',
        body: ['Without a solid knowledge base, building a balanced list of colleges that are a good academic and cultural fit is a daunting challenge.', 'I help students build a portfolio of target universities that match their personal interests and long term goals.']
    },
    {
        title: `Application guidance`,
        icon: icon1,
        id: 'tab-3',
        body: ['I take a long-term holistic approach by helping students identify key interest areas early on and advising them on choosing classes and extracurricular activities that will help them become more competitive applicants.', 'As students begin to draft their applications, I advise them on how to highlight achievements, activities, and interests in the strongest way possible, including guidance on writing effective admissions essays',
        ]
    },
];

function Panel(props) {
    const { icon, title, onClick, selected } = props;
    return (
        <li className={`is-revealing`} onClick={onClick}>
            <div className={`tab-link ${selected ? 'is-active' : ''}`}>
                <img src={icon} alt="Tabs icon"/>
                <span className="h4 m-0">{title}</span>
            </div>
        </li>
    )
}

function Tab(props) {
    const { id, title, body, selected } = props;
    return (<div id={id} className={`tab-panel ${selected ? 'is-active' : ''}`}>
        <h2>{title}</h2>
        {body.map((text) => <p key={text}>{text}</p>)}
    </div>)
}

function Features(props) {

    const [activeTab, setActiveTab] = useState('tab-1');

    const handleToggle = (id) => {
        setActiveTab(id)
    };

    return (
        <section id={`services`} className="features-tabs section">
            <div className="pricing-right-decoration"/>
            <div className="container">
                <div className="features-tabs-inner section-inner has-top-divider">
                    <div className="tabs-container">
                        <ul className="tabs-links">
                            {tabsData.map(({ title, icon, id }) => {
                                const onClick = handleToggle.bind(null, id);
                                return (<Panel
                                    key={id}
                                    onClick={onClick}
                                    title={title}
                                    icon={icon}
                                    selected={id === activeTab}
                                />)
                            })}
                        </ul>
                        <div className="tabs-content">
                            {tabsData.map(({ id, title, body }) => {
                                return (
                                    <Tab selected={id === activeTab}
                                         key={id}
                                         id={id}
                                         title={title}
                                         body={body}/>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Features.propTypes = {};

export default Features;
