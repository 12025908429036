import React, { Component } from 'react';
import './scss/style.css';
import SEO from './components/seo';
import Hero from './components/hero';
import About from './components/about';
import Features from './components/features';
import Faqs from './components/faqs';
import Contact from './components/contact';
import Footer from './components/footer';

import scrollReveal from './js/scrollReveal';

class App extends Component {
    componentDidMount() {
        scrollReveal();
    }

    render() {
        return (

            <div className="body-wrap boxed-container">
                <SEO
                    title={'🎓 Katie Whitman College Counseling Services'}
                    description={'College Counseling Services'}
                    author={'Katherine Whitman'}
                    lang={'en'}
                    keywords={['college counseling, china, chinese-american, college counseling, speaking chinese college counsellor, Bellevue, seattle, us college counsellor, us colleges, essay writing, interview preparation, essay editing, essay, personal statement, common application, SAT, ACT, GRE, GMAT, graduate, liberal arts, us news, best colleges, 美国大学古文顾问, 双语, harvard, mit, stanford, dartmouth, columbia, yale, princeton, tsinghua university, beijing']}

                />
                <main>
                    <Hero/>
                    <Features/>
                    <About/>
                    {/*<Faqs/>*/}
                    <Contact/>
                </main>
                <Footer/>
            </div>

        );
    }
}

export default App;
